import React from 'react';
import { plansData } from '../../../utils/common';
import Card from './Card/Card';
import './GetStarted.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function GetStarted() {
    //TODO change button with link
    const redirectHandler = () => {
        window.open('https://www.shelly.com/en-de/products/product-overview#unfiltered');
    };

    return (
        <section className="get-started-learn-more-section">
            <div className="learn-more-wrapper">
                <section className="learn-more">
                    <h3 className="section-title">
                        Discover Shelly controllers and start your smart journey
                        today!
                    </h3>
                    <span>
                        <button className="btn-blue" onClick={redirectHandler}>
                            Visit online store
                        </button>
                    </span>
                </section>
            </div>
            <section className="get-started">
                <h3 className="section-title">Get started with the Platform</h3>
                <div className="get-started-description">
                    <p>We believe in simplicity and user-friendliness:</p>
                    <div className="flex description-section">
                        <div className="registration-wrapper">
                            <img
                                src="./images/icons/mdi_register-outline.png"
                                alt="register icon"
                            />
                            <h5>Registration:</h5>
                            <p>
                                After registration, users <br /> receive login
                                details via email
                            </p>
                        </div>
                        <div>
                            <img
                                src="./images/icons/ph_password.png"
                                alt="password icon"
                            />
                            <h5>Password Update:</h5>
                            <p>
                                Set your password for enhanced <br /> security
                            </p>
                        </div>
                        <div>
                            <img
                                src="./images/icons/carbon_application-mobile.png"
                                alt="mobile icon"
                            />
                            <h5>Mobile App:</h5>
                            <p>
                                Download the app or scan a <br /> QR code for
                                easy access
                            </p>
                        </div>
                        <div className="line-detail left">
                            <img src="./images/line-detail.png" alt="line" />
                        </div>
                        <div className="line-detail right">
                            <img src="./images/line-detail.png" alt="line" />
                        </div>
                        <div id="get-started"></div>
                    </div>
                </div>
                <div className="plan-cards-wrapper flex">
                    {plansData.map((e, i) => (
                        <Card {...e} key={i} />
                    ))}
                </div>

                <div className="carousel-wrapper">
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className={`mySwiper`}
                        slidesPerView={'auto'}
                        spaceBetween={20}
                    >
                        {plansData.map((e, i) => (
                            <SwiperSlide key={i}>
                                <Card {...e} key={i} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
        </section>
    );
}
